import axios from 'axios';
import router from '../router';
import { setIdToken, getIdToken } from "../auth";

const defaultConfiguration = {
    configurationId: "",
    configurationName: "",
    configurationDuration: 30,
    jursidiction: "",
    county: "",
    state: "",
    incidents: [

    ],
    resources: [

    ],
    notify: [

    ]
};

export default {
    loadConfigurationList({ commit }) {
        var api = "/api/configuration";
        var token = getIdToken();
        axios.get(api, {
            headers: { 'Authorization': "bearer " + token }
        })
            .then((res) => {
                commit("LOAD_CONFIGURATIONLIST_SUCCESS", res.data);
            }, (err) => {
                commit("LOAD_CONFIGURATIONLIST_ERROR", err.data);
            });
    },
    loadAccount({ commit }) {
        commit("LOAD_ACCOUNT_START");
        var api = "/api/account";
        var token = getIdToken();
        axios.get(api, {
            headers: { 'Authorization': "bearer " + token }
        })
            .then((res) => {
                commit("LOAD_ACCOUNT_SUCCESS", res.data);
            }, (err) => {
                commit("LOAD_ACCOUNT_ERROR", err.data);
            });
    },
    saveAccount({ commit }, account) {
        commit("SAVE_ACCOUNT_START");
        var api = "/api/account";
        var token = getIdToken();
        axios.put(api, account, {
            headers: { 'Authorization': "bearer " + token }
        })
            .then((res) => {
                commit("SAVE_ACCOUNT_SUCCESS", res.data);
            }, (err) => {
                commit("SAVE_ACCOUNT_ERROR", err.response.data);
            });
    },
    loadWebhooks({ commit }) {
        commit("LOAD_WEBHOOKS_START");
        var api = "/api/webhooks";

        var token = getIdToken();
        axios.get(api, {
            headers: { 'Authorization': "bearer " + token }
        })
            .then((res) => {
                commit("LOAD_WEBHOOKS_SUCCESS", res.data);
            }, (err) => {
                commit("LOAD_WEBHOOKS_ERROR", err.data);
            });
    },
    addWebhook({ commit }, webhook) {
        commit("SAVE_WEBHOOK_START");
        var api = "/api/webhooks";
        var token = getIdToken();
        axios.post(api, webhook, {
            headers: { 'Authorization': "bearer " + token }
        })
            .then((res) => {
                commit("SAVE_WEBHOOK_SUCCESS", res.data);
            }, (err) => {
                commit("SAVE_WEBHOOK_ERROR", err.response.data);
            });
    },
    deleteWebhook({ commit }, webhookId) {
        commit("SAVE_WEBHOOK_START");
        var api = "/api/webhooks/" + webhookId;
        var token = getIdToken();

        axios.delete(api, {
            headers: { 'Authorization': "bearer " + token }
        })
            .then((res) => {
                commit("SAVE_WEBHOOK_SUCCESS", res.data);
            }, (err) => {
                commit("SAVE_WEBHOOK_ERROR", err.response.data);
            });
    },
    toggleWebhook({ commit }, webhookId) {
        commit("SAVE_WEBHOOK_START");
        var api = "/api/webhooks/" + webhookId;
        var token = getIdToken();

        axios.put(api, {}, {
            headers: { 'Authorization': "bearer " + token }
        })
            .then((res) => {
                commit("SAVE_WEBHOOK_SUCCESS", res.data);
            }, (err) => {
                commit("SAVE_WEBHOOK_ERROR", err.response.data);
            });
    },
    loadSubscription({ commit }) {
        var api = "/api/subscription";
        var token = getIdToken();
        axios.get(api, {
            headers: { 'Authorization': "bearer " + token }
        })
            .then((res) => {
                commit("LOAD_SUBSCRIPTION_SUCCESS", res.data);
            }, (err) => {
                commit("LOAD_SUBSCRIPTION_ERROR", err.data);
            });
    },
    setLocalConfigurationId({ commit }, configId) {
        commit("SET_LOCAL_CONFIGURATION", configId);
        var api = "/api/configuration/" + configId;
        var token = getIdToken();
        axios.get(api, {
            headers: { 'Authorization': "bearer " + token }
        })
            .then((res) => {
                commit("SYNC_RESYNC", res.data);
            });
    },
    connectionCountUpdate({ commit }, { type, data }) {
        commit(type, data);
    },
    updateResource({ commit }, { type, data }) {
        commit(type, data);
    },
    updateIncident({ commit }, { type, data }) {
        commit(type, data);
    },
    syncConfiguration({ commit }, { type, data }) {
        commit(type, data);
    },
    resync({ commit }, { type, data }) {
        commit(type, data);
    },
    login({ commit }, { redirect, emailAddress, password }) {
        var api = "/authenticate";
        commit("LOGIN_START");
        axios
            .post(api, {
                emailAddress: emailAddress,
                password: password
            })
            .then(
                res => {
                    setIdToken(res.data);
                    commit("LOGIN_SUCCESS", res.data);
                    if (redirect !== undefined) {
                        router.push({ path: redirect });
                    } else {
                        router.push({ name: "configurationList" });
                    }
                },
                err => {
                    if (err.response.data && err.response.data.error) {
                        commit("LOGIN_FAILURE", err.response.data.error);
                    } else {
                        commit("LOGIN_FAILURE", "There was an error logging in.  We've received notice and will investigate.");
                    }
                }
            );
    },
    createAccount({ commit }, userData) {
        var api = "/account";
        commit("CREATE_ACCOUNT_START");
        axios.
            post(api, userData)
            .then(res => {
                setIdToken(res.data);
                commit("LOGIN_SUCCESS", res.data);
                router.push({ name: "createSubscription" });
            }, err => {
                if (err.response.data) {
                    commit("CREATE_ACCOUNT_FAILURE", err.response.data);
                } else {
                    commit("CREATE_ACCOUNT_FAILURE", "There was an error creating your account.  We've received notice and will investigate.");
                }
            });
    },
    resetCreateAccount({ commit }) {
        commit("RESET_CREATE_ACCOUNT");
    },
    resetPassword({ commit }, { emailAddress }) {
        var api = "api/resetpassword";
        commit("RESET_PASSWORD_START");
        axios.
            post(api, {
                emailAddress: emailAddress
            })
            .then(res => {
                commit("RESET_PASSWORD_SUCCESS", res.data);
            }, err => {
                if (err.response.data) {
                    commit("RESET_PASSWORD_FAILURE", err.response.data);
                } else {
                    commit("RESET_PASSWORD_FAILURE", "There was an error resetting your password.  We've received notice and will investigate.");
                }
            });
    },
    processResetPassword({ commit }, reset) {
        var api = "api/processpasswordrequest";
        commit("RESET_PASSWORD_START");
        axios.
            post(api, reset)
            .then(() => {
                router.push({ name: "login" });
            }, err => {
                if (err.response.data) {
                    commit("RESET_PASSWORD_FAILURE", err.response.data);
                } else {
                    commit("RESET_PASSWORD_FAILURE", "There was an error resetting your password.  We've received notice and will investigate.");
                }
            });
    },
    loadAdminUserList({ commit }, { pagination, search }) {
        var api = "/api/admin/users";
        commit("LOADING_ADMIN_USER_LIST");

        var token = getIdToken();
        axios.get(api, {
            headers: { 'Authorization': "bearer " + token },
            params: {
                ...pagination,
                searchText: search
            }
        })
            .then(res => {
                commit("ADMIN_USER_LIST_SUCCESS", res.data);
            }, err => {
                if (err.response.data && err.response.data.error) {
                    commit("ADMIN_USER_LIST_FAILURE", err.response.data.error);
                } else {
                    commit("ADMIN_USER_LIST_FAILURE", "There was an error loading user list.  We've received notice and will investigate.");
                }
            });
    },
    loadAdminUser({ commit }, { userId }) {
        var api = "/api/admin/users";
        commit("LOADING_ADMIN_USER");

        var token = getIdToken();
        axios.get(api, {
            headers: { 'Authorization': "bearer " + token },
            params: {
                userId: userId
            }
        })
            .then(res => {
                commit("ADMIN_USER_SUCCESS", res.data);
            }, err => {
                if (err.response.data && err.response.data.error) {
                    commit("ADMIN_USER_FAILURE", err.response.data.error);
                } else {
                    commit("ADMIN_USER_FAILURE", "There was an error loading user list.  We've received notice and will investigate.");
                }
            });
    },
    newAdminUser({ commit }, newUser) {
        var api = "/api/admin/users/new";
        commit("SAVING_ADMIN_USER");

        var token = getIdToken();
        axios.post(api, newUser, {
            headers: { 'Authorization': "bearer " + token }
        })
            .then(res => {
                commit("SAVING_ADMIN_USER_SUCCESS", res.data);
            }, err => {
                if (err.response.data && err.response.data.error) {
                    commit("SAVING_ADMIN_USER_FAILURE", err.response.data.error);
                } else if (err.response.data && err.response.data.errors) {
                    commit("SAVING_ADMIN_USER_FAILURE_LIST", err.response.data.errors);
                } else {
                    commit("SAVING_ADMIN_USER_FAILURE", "There was an error creating the user.  We've received notice and will investigate.");
                }
            });
    },
    saveAdminUser({ commit }, { userId, loadedUser }) {
        var api = "/api/admin/users";
        commit("SAVING_ADMIN_USER");

        var token = getIdToken();
        axios.post(api, loadedUser, {
            headers: { 'Authorization': "bearer " + token },
            params: {
                userId: userId
            }
        })
            .then(res => {
                commit("SAVING_ADMIN_USER_SUCCESS", res.data);
            }, err => {
                if (err.response.data && err.response.data.errors) {
                    commit("SAVING_ADMIN_USER_FAILURE", err.response.data.errors);
                } else {
                    commit("SAVING_ADMIN_USER_FAILURE", "There was an error saving the user.  We've received notice and will investigate.");
                }
            });
    },
    adminDeleteUser({ commit, dispatch }, { userId }) {
        var api = `/api/admin/users/${userId}`;

        var token = getIdToken();
        axios.delete(api, {
            headers: { 'Authorization': "bearer " + token }
        }).then(res => {
            commit("SAVING_ADMIN_USER_SUCCESS", res.data);
            dispatch("loadAdminUserList");
        }, () => {
            commit("SAVING_ADMIN_USER_FAILURE", "There was an error deleting the user.  We've received notice and will investigate.");
        });
    },
    adminResetPassword({ commit }, { userId, newPassword }) {
        var api = `/api/admin/users/${userId}/password`;

        var token = getIdToken();
        axios.put(api, { newPassword: newPassword }, {
            headers: { 'Authorization': "bearer " + token }
        }).then(res => {
            commit("ADMIN_USER_RESET_PASSWORD_SUCCESS", res.data);
        }, err => {
            if (err.response.data) {
                commit("ADMIN_USER_RESET_PASSWORD_FAILURE", err.response.data);
            } else {
                commit("ADMIN_USER_RESET_PASSWORD_FAILURE", "There was an error resettig the password.  We've received notice and will investigate.");
            }
        });
    },
    loadAdminConfigurationList({ commit }) {
        var api = "/api/admin/configurations";
        commit("LOADING_ADMIN_CONFIGURATION_LIST");

        var token = getIdToken();
        axios.get(api, {
            headers: { 'Authorization': "bearer " + token }
        })
            .then(res => {
                commit("ADMIN_CONFIGURATION_LIST_SUCCESS", res.data);
            }, err => {
                if (err.response.data && err.response.data.error) {
                    commit("ADMIN_CONFIGURATION_LIST_FAILURE", err.response.data.error);
                } else {
                    commit("ADMIN_CONFIGURATION_LIST_FAILURE", "There was an error loading configuration list.  We've received notice and will investigate.");
                }
            });
    },
    loadAdminConfiguration({ commit }, { configId }) {
        var api = "/api/admin/configurations";
        commit("LOADING_ADMIN_CONFIGURATION");

        if (configId.toLowerCase() === "new") {
            commit("ADMIN_CONFIGURATION_SUCCESS", defaultConfiguration);
        }

        var token = getIdToken();
        axios.get(api, {
            headers: { 'Authorization': "bearer " + token },
            params: {
                configId: configId
            }
        })
            .then(res => {
                commit("ADMIN_CONFIGURATION_SUCCESS", res.data);
            }, err => {
                if (err.response.data && err.response.data.error) {
                    commit("ADMIN_CONFIGURATION_FAILURE", err.response.data.error);
                } else {
                    commit("ADMIN_CONFIGURATION_FAILURE", "There was an error loading configuration.  We've received notice and will investigate.");
                }
            });
    },
    adminAddNewIncident({ commit }) {
        commit("ADMIN_CONFIGURATION_ADD_INCIDENT");
    },
    adminAddNewResource({ commit }) {
        commit("ADMIN_CONFIGURATION_ADD_RESOURCE");
    },
    adminAddNewNotification({ commit }) {
        commit("ADMIN_CONFIGURATION_ADD_NOTIFICATION");
    },
    saveAdminConfiguration({ commit }, { configId, loadedConfiguration }) {
        var api = "/api/admin/configurations";
        commit("SAVING_ADMIN_CONFIGURATION");

        var token = getIdToken();
        axios.post(api, loadedConfiguration, {
            headers: { 'Authorization': "bearer " + token, "X-OriginalId": configId },
            params: {
                configId: configId
            }
        })
            .then(res => {
                commit("SAVING_ADMIN_CONFIGURATION_SUCCESS", res.data);
                router.push({ name: 'adminConfigurationList' });
            }, err => {
                if (err.response.data && err.response.data.error) {
                    commit("SAVING_ADMIN_CONFIGURATION_FAILURE", err.response.data.error);
                } else {
                    commit("SAVING_ADMIN_CONFIGURATION_FAILURE", "There was an error saving configuration.  We've received notice and will investigate.");
                }
            });
    },
    loadAdminAgencyList({ commit }) {
        var api = "/api/admin/agencies";
        commit("LOADING_ADMIN_AGENCY_LIST");

        var token = getIdToken();
        axios.get(api, {
            headers: { 'Authorization': "bearer " + token }
        })
            .then(res => {
                commit("ADMIN_AGENCY_LIST_SUCCESS", res.data);
            }, err => {
                if (err.response.data && err.response.data.error) {
                    commit("ADMIN_AGENCY_LIST_FAILURE", err.response.data.error);
                } else {
                    commit("ADMIN_AGENCY_LIST_FAILURE", "There was an error loading agency list.  We've received notice and will investigate.");
                }
            });
    },
    loadAdminAgency({ commit }, { agencyId }) {
        var api = "/api/admin/agencies";
        commit("LOADING_ADMIN_AGENCY");

        if (agencyId.toLowerCase() === "new") {
            commit("LOAD_ADMIN_AGENCY_SUCCESS", defaultConfiguration);
        }

        var token = getIdToken();
        axios.get(api, {
            headers: { 'Authorization': "bearer " + token },
            params: {
                agencyId: agencyId
            }
        })
            .then(res => {
                commit("LOAD_ADMIN_AGENCY_SUCCESS", res.data);
            }, err => {
                if (err.response.data && err.response.data.error) {
                    commit("LOAD_ADMIN_AGENCY_FAILURE", err.response.data.error);
                } else {
                    commit("LOAD_ADMIN_AGENCY_FAILURE", "There was an error loading agency.  We've received notice and will investigate.");
                }
            });
    },
    saveAdminAgency({ commit }, { loadedAgency }) {
        var api = "/api/admin/agencies";
        commit("SAVING_ADMIN_AGENCIES");

        var token = getIdToken();
        axios.post(api, loadedAgency, {
            headers: { 'Authorization': "bearer " + token }
        })
            .then(res => {
                commit("SAVING_ADMIN_AGENCIES_SUCCESS", res.data);
                router.push({ name: 'adminAgencyList' });
            }, err => {
                if (err.response.data) {
                    commit("SAVING_ADMIN_AGENCIES_FAILURE", err.response.data);
                } else {
                    commit("SAVING_ADMIN_AGENCIES_FAILURE", "There was an error saving configuration.  We've received notice and will investigate.");
                }
            });
    },
    stopBlink({ commit }) {
        commit("STOP_BLINK");
    },
    syncHubConnection({ commit }, data) {
        commit(data);
    }
}