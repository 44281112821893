import { find } from "../util";
import { shouldBlink } from "./blinkChecker";
import { each, map, join, isArray, filter } from "lodash";

function updateConfiguration(state, configId) {
    configId = configId.toLowerCase();
    if (state.selectedConfigurationId !== "") {
        var configuration = find(state.configurationList, p => p.configurationId.toLowerCase() === configId);
        state.selectedConfiguration = configuration;
    }
}

function updateResources(state) {
    if (state.selectedConfiguration && state.selectedConfiguration.resources) {
        state.resourceList = state.selectedConfiguration.resources;
    }
    else {
        state.resourceList = [];
    }
}

function getAddressUrl(incident, isRestricted) {
    //if (isRestricted) return "";  remove comment after Restricted/Unrestricted users are sorted out...

    var url = "nipst:livecad|" +
        encodeURIComponent(incident.address) + "|" +
        encodeURIComponent(incident.city) + "|" +
        encodeURIComponent(incident.state) + "|" +
        encodeURIComponent(incident.zipCode) + "|" +
        encodeURIComponent(incident.county) + "|" +
        encodeURIComponent(incident.latitude) + "|" +
        encodeURIComponent(incident.longitude) + "|" +
        encodeURIComponent(incident.agencyId) + "|" +
        encodeURIComponent(incident.incidentId);

    if (isRestricted) return url;       // remove after Restricted/Unrestricted users are sorted out...

    return url;
}

function getCityUrl(incident, isRestricted) {
    var latLongUrl = "http://maps.google.com/maps?q={0},{1}&z=16";
    var cityZipUrl = "http://maps.google.com/maps?q={0},+{1}&z=16";
    var fullUrl = "#";
    if (incident.latitude !== 0) {
        fullUrl = latLongUrl
            .replace("{0}", isRestricted ? incident.latitudeRES : incident.latitude)
            .replace("{1}", isRestricted ? incident.longitudeRES : incident.longitude);
        return fullUrl;
    } else if (incident.zipCode.trim() !== "") {
        fullUrl = cityZipUrl
            .replace("{0}", isRestricted ? incident.addressRES : incident.address)
            .replace("{1}", incident.zipCode);
        return fullUrl;
    } else {
        return fullUrl;
    }
}

function updateIncidents(incidentList, isRestricted) {
    each(incidentList, p => {
        p.cityUrl = getCityUrl(p, isRestricted);
        p.addressUrl = getAddressUrl(p, isRestricted);
    });
}

export const mutations = {
    ["LOGIN_START"](state) {
        state.isLoggingIn = true;
        state.loginError = "";
    },
    ["LOGIN_SUCCESS"](state, results) {
        state.isLoggingIn = false;
        state.isCreatingAccount = false;
        state.loggedInAs = results.emailAddress;
    },
    ["LOGIN_FAILURE"](state, results) {
        state.isLoggingIn = false;
        state.loginError = results;
    },
    ["RESET_PASSWORD_START"](state) {
        state.resetPasswordError = [];
    },
    ["RESET_PASSWORD_SUCCESS"](state) {
        state.hasResetPassword = true;
    },
    ["RESET_PASSWORD_FAILURE"](state, results) {
        state.resetPasswordError = results;
    },
    ["CREATE_ACCOUNT_START"](state) {
        state.isCreatingAccount = true;
        state.createAccountFailureReasons = {};
    },
    ["CREATE_ACCOUNT_FAILURE"](state, results) {
        state.isCreatingAccount = false;
        state.createAccountFailureReasons = results;
    },
    ["RESET_CREATE_ACCOUNT"](state) {
        state.isCreatingAccount = false;
        state.createAccountFailureReasons = [];
    },
    ["LOAD_CONFIGURATIONLIST_SUCCESS"](state, results) {
        if (state.account.isAdmin) {
            state.configurationList = results;
        } else {
            // filter out TEST-
            state.configurationList = filter(results, (p) => p.configurationId.indexOf("TEST") < 0);
        }
        state.isLoadingConfigurationList = false;

        updateConfiguration(state, state.selectedConfigurationId);
        if (state.serverStartup === undefined) {
            updateResources(state);
        }
    },
    ["LOAD_CONFIGURATIONLIST_ERROR"](state, error) {
        state.configurationListError = error;
        state.isLoadingConfigurationList = false;
    },
    ["LOAD_ACCOUNT_START"](state){
        state.isLoadingAccount = true;
        state.accountLoadError = "";
    },
    ["LOAD_ACCOUNT_SUCCESS"](state, data) {
        state.isLoadingAccount = false;
        state.account = data;
    },
    ["LOAD_ACCOUNT_ERROR"](state, error) {
        state.isLoadingAccount = false;
        state.accountLoadError = error;
    },
    ["SAVE_ACCOUNT_START"](state) {
        state.isLoadingAccount = true;
    },
    ["SAVE_ACCOUNT_SUCCESS"](state) {
        state.isLoadingAccount = false;
    },
    ["SAVE_ACCOUNT_ERROR"](state, error) {
        state.isLoadingAccount = false;
        state.accountLoadError = error;
    },
    ["LOAD_WEBHOOKS_START"](state){
        state.isLoadingAccount = true;
    },
    ["LOAD_WEBHOOKS_SUCCESS"](state, data){
        state.isLoadingAccount = false;
        state.webhooks = data;
    },
    ["LOAD_WEBHOOKS_ERROR"](state, error){
        state.isLoadingAccount = false;
        state.webhookError = error.detail;
    },
    ["SAVE_WEBHOOK_START"](state){
        state.isLoadingAccount = true;
    },
    ["SAVE_WEBHOOK_SUCCESS"](state, data){
        state.isLoadingAccount = false;
        state.webhooks = data;
    },
    ["SAVE_WEBHOOK_ERROR"](state, error){
        state.isLoadingAccount = false;
        state.webhookError = error.detail;
    },
    ["LOAD_SUBSCRIPTION_SUCCESS"](state, data) {
        state.isLoadingSubscription = false;
        state.subscription = data;
    },
    ["LOAD_SUBSCRIPTION_ERROR"](state) {
        state.isLoadingSubscription = false;
        state.subscription = {};
    },
    ["SET_LOCAL_CONFIGURATION"](state, data) {
        state.selectedConfigurationId = data;
        state.incidentList = [];
        state.resourceList = [];
        state.isLoadingInitialSync = true;

        updateConfiguration(state, data);
        updateResources(state);
    },
    ["SYNC_CREATE_INCIDENT"](state, data) {
        var index = state.incidentList.findIndex(p => {
            return p.incidentId === data.incidentId;
        });
        if (index < 0) {
            if (shouldBlink(state.selectedConfiguration.notify, data)) {
                state.blink = true;
                data.causedBlink = true;
            }

            state.incidentList.push(data);
        }
        updateIncidents(state.incidentList, state.account.isRestricted);
    },
    ["SYNC_UPDATE_INCIDENT"](state, data) {
        var index = state.incidentList.findIndex(p => {
            return p.incidentId === data.incidentId;
        });

        if (index >= 0) {
            var oldInc = state.incidentList[index];

            if (shouldBlink(state.selectedConfiguration.notify, data, oldInc)) {
                state.blink = true;
                data.causedBlink = true;
            }

            state.incidentList.splice(index, 1, data);
        } else {
            if (shouldBlink(state.selectedConfiguration.notify, data)) {
                state.blink = true;
                data.causedBlink = true;
            }

            state.incidentList.push(data);
        }
        updateIncidents(state.incidentList, state.account.isRestricted);
    },
    ["SYNC_REMOVE_INCIDENT"](state, data) {
        var index = state.incidentList.findIndex(p => {
            return p.incidentId === data.incidentId;
        });
        state.incidentList.splice(index, 1);
        updateIncidents(state.incidentList, state.account.isRestricted);
    },
    ["SYNC_CREATE_RESOURCE"](state, data) {
        var index = state.resourceList.findIndex(p => {
            return p.resourceId === data.resourceId;
        });
        if (index < 0) state.resourceList.push(data);
    },
    ["SYNC_UPDATE_RESOURCE"](state, data) {
        var index = state.resourceList.findIndex(p => {
            return p.resourceId === data.resourceId;
        });

        state.resourceList.splice(index, 1, data);
    },
    ["SYNC_REMOVE_RESOURCE"](state, data) {
        var index = state.resourceList.findIndex(p => {
            return p.resourceId === data.resourceId;
        });
        state.resourceList.splice(index, 1);
    },
    ["SYNC_CONFIGURATION_UPDATED"]() {
        location.reload();
    },
    ["SYNC_CONNECTION_COUNT"](state, data) {
        state.connectedCountExceeded = data;
    },
    ["SYNC_HUB_CONNECTING"](state) {
        state.syncHubConnecting = true;
        state.syncHubConnected = false;
    },
    ["SYNC_HUB_CONNECTED"](state) {
        state.syncHubConnecting = false;
        state.syncHubConnected = true;
    },
    ["SYNC_HUB_CONNECTION_FAILURE"](state) {
        state.syncHubConnecting = false;
        state.syncHubConnected = false;
    },
    ["SYNC_HUB_DISCONNECTED"](state) {
        state.syncHubConnecting = false;
        state.syncHubConnected = false;
    },
    ["SYNC_BLINK"](state) {
        state.blink = true;
    },
    ["STOP_BLINK"](state) {
        state.blink = false;

        each(state.incidentList, function (incident) {
            incident.causedBlink = false;
            each(incident.assignedResources, function (ar) {
                ar.causedBlink = false;
            });
        });
    },
    ["SYNC_RESYNC_START"]() {
        // this is here for debug timing
    },
    ["SYNC_RESYNC"](state, data) {
        state.serverStartup = data.serverStartup;
        state.resourceList = data.resources;
        state.incidentList = data.incidents;

        updateIncidents(state.incidentList, state.account.isRestricted);
        state.isLoadingInitialSync = false;
    },
    ["LOADING_ADMIN_AGENCY_LIST"](state) {
        state.admin.isLoadingAgencies = true;
    },
    ["ADMIN_AGENCY_LIST_SUCCESS"](state, data) {
        state.admin.isLoadingAgencies = false;
        state.admin.agencyList = data;
    },
    ["ADMIN_AGENCY_LIST_FAILURE"](state, data) {
        state.admin.isLoadingAgencies = false;
        state.admin.errorLoadingAgencies = data;
    },
    ["LOADING_ADMIN_USER_LIST"](state) {
        state.admin.isLoadingUsers = true;
    },
    ["ADMIN_USER_LIST_SUCCESS"](state, data) {
        state.admin.isLoadingUsers = false;
        state.admin.userPagination = {
            sortDesc: data.descending,
            page: data.page,
            itemsPerPage: data.rowsPerPage,
            sortBy: data.sortBy
        };
        state.admin.userListTotalItems = data.totalItems;
        state.admin.userList = data.items;
    },
    ["ADMIN_USER_LIST_FAILURE"](state, data) {
        state.admin.isLoadingUsers = false;
        state.admin.errorLoadingUsers = data;
    },
    ["ADMIN_USER_LIST_PAGINATION_UPDATE"](state, data) {
        state.admin.userPagination = data;
    },
    ["LOADING_ADMIN_CONFIGURATION_LIST"](state) {
        state.admin.isLoadingConfiguations = true;
    },
    ["ADMIN_CONFIGURATION_LIST_SUCCESS"](state, data) {
        state.admin.isLoadingConfiguations = false;
        state.admin.configurationList = data;
    },
    ["ADMIN_CONFIGURATION_LIST_FAILURE"](state, data) {
        state.admin.isLoadingConfiguations = false;
        state.admin.errorLoadingConfiguration = data;
    },
    ["LOADING_ADMIN_USER"](state) {
        state.admin.isLoadingUsers = true;
        state.admin.loadedUser = undefined;
    },
    ["ADMIN_USER_SUCCESS"](state, data) {
        state.admin.isLoadingUsers = false;
        state.admin.loadedUser = data[0];
    },
    ["ADMIN_USER_FAILURE"](state, data) {
        state.admin.isLoadingUsers = false;
        state.admin.errorLoadingUsers = data;
    },
    ["SAVING_ADMIN_USER"](state) {
        state.admin.isSavingUser = true;
    },
    ["SAVING_ADMIN_USER_SUCCESS"](state) {
        state.admin.isSavingUser = false;
        state.admin.hasSavedUserRedirect = new Date();
    },
    ["SAVING_ADMIN_USER_FAILURE"](state, data) {
        state.admin.isSavingUser = false;

        if (isArray(data)) {
            var justDescriptions = map(data, p => p[0]);
            state.admin.errorSavingUser = join(justDescriptions, " ");
        } else {
            state.admin.errorSavingUser = data;
        }
    },
    ["SAVING_ADMIN_USER_FAILURE_LIST"](state, data) {
        state.admin.isSavingUser = false;
        state.admin.errorSavingUserList = data;
    },
    ["ADMIN_USER_RESET_PASSWORD_SUCCESS"](state) {
        state.admin.isSavingUser = false;
        state.admin.errorSavingUser = undefined;
        state.admin.hasSavedUserRedirect = new Date();
    },
    ["ADMIN_USER_RESET_PASSWORD_FAILURE"](state, data) {
        state.admin.isSavingUser = false;

        var justDescriptions = map(data, p => p.description);
        state.admin.errorSavingUser = join(justDescriptions, " ");
    },
    ["LOADING_ADMIN_CONFIGURATION"](state) {
        state.admin.isLoadingConfiguations = true;
        state.admin.loadedConfiguration = undefined;
    },
    ["ADMIN_CONFIGURATION_SUCCESS"](state, data) {
        state.admin.isLoadingConfiguations = false;
        state.admin.loadedConfiguration = data;
    },
    ["ADMIN_CONFIGURATION_FAILURE"](state, data) {
        state.admin.isLoadingConfiguations = false;
        state.admin.errorLoadingConfigurations = data;
    },
    ["SAVING_ADMIN_CONFIGURATION"](state) {
        state.admin.isSavingConfiguration = true;
        state.admin.errorSavingConfiguration = "";
    },
    ["SAVING_ADMIN_CONFIGURATION_SUCCESS"](state) {
        state.admin.isSavingConfiguration = false;
        state.admin.hasSavedConfigurationRedirect = new Date();
    },
    ["SAVING_ADMIN_CONFIGURATION_FAILURE"](state, data) {
        state.admin.isSavingConfiguration = false;
        state.admin.errorSavingConfiguration = data;
    },
    ["ADMIN_CONFIGURATION_ADD_INCIDENT"](state) {
        state.admin.loadedConfiguration.incidents.push({
            agencyId: "",
            cities: [],
            zones: [],
            counties: [],
            resourceDepartments: [],
            resources: []
        });
    },
    ["ADMIN_CONFIGURATION_ADD_RESOURCE"](state) {
        state.admin.loadedConfiguration.resources.push({
            resourceId: "",
            resourceAlias: "",
            departmentId: "",
            agencyId: "",
            divider: false
        });
    },
    ["ADMIN_CONFIGURATION_ADD_NOTIFICATION"](state) {
        state.admin.loadedConfiguration.notify.push({
            agencyId: "",
            resource: "",
            city: "",
            county: "",
            zone: "",
            resourceDepartment: "",
            resourceState: ""
        });
    },
    ["LOAD_ADMIN_AGENCY_SUCCESS"](state, result) {
        state.admin.loadedAgency = result;
    },
    ["SAVING_ADMIN_AGENCIES"](state) {
        state.admin.errorSavingAgency = "";
    },
    ["SAVING_ADMIN_AGENCIES_FAILURE"](state, result) {
        state.admin.errorSavingAgency = result;
    },
}
