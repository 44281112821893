import Vue from 'vue'
import Vuex from 'vuex'
import { mutations } from './mutations'
import actions from './actions'
import plugins from './plugins'
import { getLoggedInAs } from "../auth";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        configurationList: [],
        isLoadingConfigurationList: true,
        isLoadingInitialSync: true,
        isLoadingAccount: true,
        isLoadingSubscription: true,
        isCreatingAccount: false,
        selectedConfiguration: {},
        selectedConfigurationId: "",
        syncHubConnecting: false,
        syncHubConnected: false,
        connectedCountExceeded: false,
        resourceList: [],
        incidentList: [],
        blink: false,
        isLoggingIn: false,
        loginError: "",
        hasResetPassword: false,
        resetPasswordError: [],
        loggedInAs: getLoggedInAs(),
        accountLoadError: "",
        createAccountFailureReasons: {
            EmailAddress: [],
            Password: [],
            PhoneNumber : [],
        },
        stripePublishableKey: getCookie("X-StripePublishableKey"),
        host: decodeURIComponent(getCookie("X-Host")),
        account: {
            firstName: "",
            lastName: "",
            deparmentName: "",
            address1: "",
            address2: "",
            city: "",
            state: "",
            zipCode: "",
            externalAccessToken: "",
            isRestricted: true
        },
        webhooks:[],
        webhookError: "",
        subscription: {},
        admin: {
            isLoadingConfiguations: false,
            isLoadingUsers: false,
            isLoadingAgencies: false,
            isSavingUser: false,
            isSavingConfiguration: false,
            errorLoadingConfiguration: undefined,
            errorLoadingUsers: undefined,
            errorLoadingAgencies: undefined,
            errorSavingUser: undefined,
            errorSavingUserList: [],
            errorSavingConfiguration: "",
            errorSavingAgency: "",
            configurationList: [],
            loadedConfiguration: undefined,
            hasSavedConfigurationRedirect: 0,
            loadedUser: undefined,
            loadedAgency: undefined,
            hasSavedUserRedirect: 0,
            userList: [],
            userPagination: {},
            userListTotalItems: 0,
            agencyList: []
        }
    },
    actions,
    mutations,
    plugins
});

function getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length == 2) return parts.pop().split(";").shift();
}