const signalR = require("@microsoft/signalr");

import { getIdToken } from "../auth";

export default {
    install(Vue, pluginOptions = {}) {
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl("/hubs/sync", { accessTokenFactory: () => getIdToken() })
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds: () => {
                    return 30000; // always 30 seconds retry
                }
            })
            .configureLogging(signalR.LogLevel.Warning)
            .build();

        this.store = pluginOptions.store;
        this.reconnectInterval = 0;

        Vue.prototype.$syncHub = {};
        Vue.prototype.$syncHub.subscribe = (configId) => {
            this.connection.invoke("SubscribeToConfiguration", configId);
        };
        Vue.prototype.$syncHub.unsubscribe = (configId) => {
            this.connection.invoke("UnsubscribeToConfiguration", configId);
        };
        Vue.prototype.$syncHub.resync = (configId) => {
            this.store.dispatch("resync", { type: "SYNC_RESYNC_START" });
            this.connection.invoke("RequestResync", configId).then(data => {
                this.store.dispatch("resync", { type: "SYNC_RESYNC", data });
            });
        };
        Vue.prototype.$syncHub.isConnectionCountMet = () => {
            this.connection.invoke("IsConnectedCountExceeded").then(data => {
                this.store.dispatch("connectionCountUpdate", { type: "SYNC_CONNECTION_COUNT", data });
            });
        };
        Vue.prototype.$syncHub.ping = (configId) => {
            this.connection.invoke("Pingity", configId);
        };
        Vue.prototype.$syncHub.start = () => {
            return this.start(this.store);
        };
        this.setupListeners(this.store);
    },
    setupListeners(store) {
        this.connection.on("createIncident", data => {
            store.dispatch("updateIncident", { type: "SYNC_CREATE_INCIDENT", data });
        });
        this.connection.on("updateIncident", data => {
            store.dispatch("updateIncident", { type: "SYNC_UPDATE_INCIDENT", data });
        });
        this.connection.on("removeIncident", data => {
            store.dispatch("updateIncident", { type: "SYNC_REMOVE_INCIDENT", data });
        });
        this.connection.on("createResource", data => {
            store.dispatch("updateResource", { type: "SYNC_CREATE_RESOURCE", data });
        });
        this.connection.on("updateResource", data => {
            store.dispatch("updateResource", { type: "SYNC_UPDATE_RESOURCE", data });
        });
        this.connection.on("removeResource", data => {
            store.dispatch("updateResource", { type: "SYNC_REMOVE_RESOURCE", data });
        });
        this.connection.on("configurationUpdated", () => {
            store.dispatch("syncConfiguration", { type: "SYNC_CONFIGURATION_UPDATED" });
        });
        this.connection.on("connectedCountExceedUpdate", () => {
            this.connection.invoke("IsConnectedCountExceeded").then(data => {
                this.store.dispatch("connectionCountUpdate", { type: "SYNC_CONNECTION_COUNT", data });
            });
        });
        this.connection.on("blink", () => {
            store.dispatch("blink", { type: "SYNC_BLINK" });
        });

        /* connection statuses */
        this.connection.onreconnecting(() => {
            store.dispatch("syncHubConnection", { type: "SYNC_HUB_CONNECTING" });
        });

        this.connection.onreconnected(() => {
            store.dispatch("syncHubConnection", { type: "SYNC_HUB_CONNECTED" });
        });

        this.connection.onclose(() => {
            store.dispatch("syncHubConnection", { type: "SYNC_HUB_DISCONNECTED" });
        });
    },
    start(store) {
        if (this.connection.state === signalR.HubConnectionState.Disconnected) {
            store.dispatch("syncHubConnection", { type: "SYNC_HUB_CONNECTING" });

            return this.connection.start().then(() => {
                store.dispatch("syncHubConnection", { type: "SYNC_HUB_CONNECTED" });
                clearInterval(this.reconnectInterval);

                this.connection.invoke("IsConnectedCountExceeded").then(data => {
                    this.store.dispatch("connectionCountUpdate", { type: "SYNC_CONNECTION_COUNT", data });
                });
            }, (res) => {
                store.dispatch("syncHubConnection", { type: "SYNC_HUB_CONNECTION_FAILURE" });
                if (res.statusCode === 401) {
                    // redirect to /logout
                    window.location.href = "/logout";
                    
                    // location.reload(); // permission issue
                }
            });
        }
    },
}