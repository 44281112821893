import "babel-polyfill";
import Vue from "vue";
import Vuetify from "vuetify/lib";
import VueMeta from "vue-meta";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import syncHub from "./services/syncHub";
import VStripeElements from "v-stripe-elements/lib";
import VueTheMask from "vue-the-mask";
import VueAppInsights from "vue-application-insights";

Vue.config.devtools = true;

// check poly-fills
if (typeof fetch !== "undefined" && typeof AbortController === "undefined") {
  console.warn(
    "Fetch is supported, but not AbortController.  Dropping default fetch so SignalR can override."
  );
  window.fetch = undefined;
}

Vue.prototype.$http = axios;
Vue.prototype.axios = axios;

import BaseLayout from "./BaseLayout";
import ConfigurationLayout from "./ConfigurationLayout";

Vue.component("base-layout", BaseLayout);
Vue.component("configuration-layout", ConfigurationLayout);

Vue.config.productionTip = true;

Vue.use(syncHub, { store });
Vue.use(VStripeElements);
Vue.use(Vuetify);
Vue.use(VueTheMask);
Vue.use(VueMeta, { refreshOnceOnNavigation: true });

if (window.settings && window.settings.applicationInsightsKey) {
  Vue.use(VueAppInsights, {
    id: window.settings.applicationInsightsKey,
    router,
  });
}

new Vue({
  router,
  store,
  vuetify: new Vuetify({
    iconfont: "mdi",
  }),
  render: (h) => h(App),
}).$mount("#app");
