import Vue from 'vue';
import Router from 'vue-router';

import { requireAuth, requireAdminAuth, loggedInRedirect } from "./auth";
import Login from "./components/Login";
import Logout from "./components/Logout";
import Home from "./components/Home";

import ConfigurationList from "./components/ConfigurationList";
import Configuration from "./components/Configuration";

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    { path: '/', name: 'home', component: Home, beforeEnter: loggedInRedirect, meta: { layout: 'base' } },
    { path: '/login', name: 'login', component: Login, meta: { layout: 'base' } },
    { path: '/logout', name: 'logout', component: Logout, meta: { layout: 'base' } },
    { path: '/forgot', name: 'forgotPassword', component: () => import("./components/ForgotPassword"), meta: { layout: 'base' } },
    { path: '/reset', name: 'resetPassword', component: () => import("./components/ResetPassword"), meta: { layout: 'base' } },
    { path: '/register', name: 'register', component: () => import("./components/Register"), meta: { layout: 'base' } },
    { path: '/terms', name: 'terms', component: () => import("./components/Terms"), meta: { layout: 'base' } },
    { path: '/subscription/create', name: 'createSubscription', redirect: '/account' },
    { path: '/subscription/success', name: 'successfulSubscription', redirect: { path: '/show/config', query: { newSubscription: true } } },
    { path: '/subscription/cancelled', name: 'cancelledSubscription', redirect: '/show/config' },
    { path: '/account', name: 'account', component: () => import("./components/Account"), beforeEnter: requireAuth, meta: { layout: 'base' } },
    { path: '/account/webhooks', name: 'webhooks', component: () => import("./components/Webhooks"), beforeEnter: requireAuth, meta: { layout: 'base' } },

    { path: '/sample', name: 'sample', component: () => import("./components/Sample"), meta: { layout: 'base' } },

    { path: '/show/config', name: 'configurationList', component: ConfigurationList, beforeEnter: requireAuth, meta: { layout: 'base' } },
    { path: '/show/config/:configId', name: 'configuration', component: Configuration, beforeEnter: requireAuth, meta: { layout: 'configuration' } },

    { path: '/admin', beforeEnter: requireAdminAuth, name: "adminMenu", component: () => import("./components/admin/AdminMenu"), meta: { layout: 'base' } },
    { path: '/admin/config', beforeEnter: requireAdminAuth, name: "adminConfigurationList", component: () => import("./components/admin/AdminConfigurationList"), meta: { layout: 'base' } },
    { path: '/admin/config/:configId', beforeEnter: requireAdminAuth, name: "adminConfigurationEdit", component: () => import("./components/admin/AdminConfigurationEdit"), meta: { layout: 'base' } },
    { path: '/admin/users', beforeEnter: requireAdminAuth, name: "adminUserList", component: () => import("./components/admin/AdminUserList"), meta: { layout: 'base' } },
    { path: '/admin/users2', beforeEnter: requireAdminAuth, name: "adminUserList2", component: () => import("./components/admin/AdminUserList2"), meta: { layout: 'base' } },
    { path: '/admin/users/new', beforeEnter: requireAdminAuth, name: "adminUserNew", component: () => import("./components/admin/AdminUserNew"), meta: { layout: 'base' } },
    { path: '/admin/users/:userId', beforeEnter: requireAdminAuth, name: "adminUserEdit", component: () => import("./components/admin/AdminUserEdit"), meta: { layout: 'base' } },
    { path: '/admin/users2/:userId', beforeEnter: requireAdminAuth, name: "adminUserEdit2", component: () => import("./components/admin/AdminUserEdit2"), meta: { layout: 'base' } },
    { path: '/admin/agencies', beforeEnter: requireAdminAuth, name: "adminAgencyList", component: () => import("./components/admin/AdminAgencyList"), meta: { layout: 'base' } },
    { path: '/admin/agencies/:agencyId', beforeEnter: requireAdminAuth, name: "adminAgencyEdit", component: () => import("./components/admin/AdminAgencyEdit"), meta: { layout: 'base' } },
    { path: '/admin/status', beforeEnter: requireAdminAuth, name: "adminRealTimeStatus", component: () => import("./components/admin/RealTimeStatus"), meta: { layout: 'base' } },

    { path: '*', component: () => import("./components/NotFound"), meta: { layout: 'base' } }
  ]
});
