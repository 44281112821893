import { some, difference, differenceWith, map, each } from "lodash";

export function shouldBlink(notifyCriteria, newIncident, oldIncident) {

    let shouldBlink = false;

    each(notifyCriteria, function (notify) {
        // notify.resource
        // notify.zone
        // notify.city

        let hasAgency = false,
            agencyMatch = false,

            hasResource = false,
            resourceMatch = false,

            resourceCallback = function () { },
            resourceDepartmentCallback = function () { },
            resourceStatusCallback = function () { },

            hasZone = false,
            zoneMatch = false,

            hasCity = false,
            cityMatch = false,

            hasCounty = false,
            countyMatch = false,

            hasResourceStatus = false,
            resourceStatusMatch = false,

            hasResourceDepartment = false,
            resourceDepartmentMatch = false,

            /* adding this check because if there is ONLY agencyId, we could
             accidentally not check it and then the blink would still occur. */
             criteriaChecked = false;

        if (notify.agencyId) {
            hasAgency = true;
            if (!oldIncident) { // match agency only on NEW incidents
                agencyMatch = (newIncident.agencyId.toLowerCase() === notify.agencyId.toLowerCase());
                criteriaChecked = true;
            } else {
                hasAgency = false;
            }
        }

        if (notify.resource) {
            hasResource = true;
            criteriaChecked = true;

            const newIncidentHasResource = some(newIncident.assignedResources, function (resource) {
                return resource.resourceId.toLowerCase() === notify.resource.toLowerCase();
            });

            // resources - does newIncident have resources, but oldIncident does not?  NOTIFY
            if (newIncident && !oldIncident) {
                // new incident, so shouldBlink should be checked
                resourceMatch = newIncidentHasResource;
            } else if (newIncident && oldIncident) {
                const oldIncidentHasResource = some(oldIncident.assignedResources, function (resource) {
                    return resource.resourceId.toLowerCase() === notify.resource.toLowerCase();
                });
                resourceMatch = (newIncidentHasResource && !oldIncidentHasResource);
            }

            if (resourceMatch) {
                resourceCallback = function () {
                    // mark RESOURCE red...
                    // get resources from newIncident NOT in oldIncident
                    if (oldIncident) {
                        const resourceDifferences = difference(map(newIncident.assignedResources, "resourceId"),
                            map(oldIncident.assignedResources, "resourceId"));

                        each(newIncident.assignedResources, function (ar) {
                            each(resourceDifferences, function (rd) {
                                if (ar.resourceId.toLowerCase() === rd.toLowerCase() &&
                                    ar.resourceId.toLowerCase() === notify.resource.toLowerCase()) {
                                    ar.causedBlink = true;
                                }
                            });
                        });
                    } else {
                        each(newIncident.assignedResources, function (ar) {
                            if (ar.resourceId.toLowerCase() === notify.resource.toLowerCase()) {
                                ar.causedBlink = true;
                            }
                        });
                    }
                }
            } else {
                // check to see if old assignedResources are marked as causedBlink
                if (oldIncident) {
                    each(newIncident.assignedResources, function (ar) {
                        const oldIncidentCausedBlink = some(oldIncident.assignedResources, function (resource) {
                            return resource.resourceId.toLowerCase() === ar.resourceId.toLowerCase() && resource.causedBlink;
                        });

                        ar.causedBlink = ar.causedBlink || oldIncidentCausedBlink;
                    });
                }
            }
        }
        if (notify.zone) {
            hasZone = true;
            criteriaChecked = true;

            const notifyZone = notify.zone.trim().toLowerCase();
            const newIncidentZone = newIncident.zone.trim().toLowerCase();

            if (notifyZone.indexOf("*") >= 0) {
                const splitZone = notify.zone.toLowerCase().split('*');
                if ((oldIncident === undefined) || (oldIncident && newIncidentZone !== oldIncident.zone.trim().toLowerCase())) {
                    if (splitZone[0].trim().length === 0) {
                        // ends with
                        zoneMatch = (newIncidentZone.endsWith(splitZone[1].trim()));
                    } else if (splitZone[1].trim().length === 0) {
                        // starts with
                        zoneMatch = (newIncidentZone.startsWith(splitZone[0].trim()));
                    }
                }
            } else {
                if (oldIncident) {
                    const oldIncidentZone = oldIncident.zone.trim().toLowerCase();
                    zoneMatch = (notifyZone === newIncidentZone &&
                        newIncidentZone !== oldIncidentZone);
                } else {
                    zoneMatch = (notifyZone === newIncidentZone);
                }
            }
        }

        if (notify.city) {
            hasCity = true;
            criteriaChecked = true;

            const notifyCity = notify.city.trim().toLowerCase();
            const newIncidentCity = newIncident.city.trim().toLowerCase();
            if (oldIncident) {
                if (oldIncident.city.trim().toLowerCase() !== newIncidentCity) {
                    cityMatch = notifyCity === newIncidentCity;
                }
            } else {
                cityMatch = notifyCity === newIncidentCity;
            }
        }

        if (notify.resourceDepartment) {
            hasResourceDepartment = true;
            criteriaChecked = true;

            each(newIncident.assignedResources, function (resource) {
                if (resource.departmentId.trim() === notify.resourceDepartment) {

                    // if oldIncident had resourceId and departmentId, no notify
                    if (oldIncident) {
                        const oldIncidentHasResource = some(oldIncident.assignedResources, function (oldResource) {
                            const rMatch = oldResource.resourceId.toLowerCase() === resource.resourceId.toLowerCase();
                            const dMatch = oldResource.departmentId.toLowerCase() === resource.departmentId.toLowerCase();

                            return rMatch && dMatch;
                        });
                        //if (oldIncident.assignedResources.length === 0) {
                        //    resource.causedBlink = true;
                        //}
                        if (oldIncidentHasResource === false) {
                            resourceDepartmentMatch = true;

                            resourceDepartmentCallback = function () {
                                // mark RESOURCE red...
                                // get resources from newIncident NOT in oldIncident
                                if (oldIncident) {
                                    const resourceDifferences = difference(map(newIncident.assignedResources, "resourceId"),
                                        map(oldIncident.assignedResources, "resourceId"));

                                    each(newIncident.assignedResources, function (ar) {
                                        each(resourceDifferences, function (rd) {
                                            if (ar.resourceId.toLowerCase() === rd.toLowerCase() &&
                                                ar.departmentId.toLowerCase() === notify.resourceDepartment.toLowerCase()) {
                                                ar.causedBlink = true;
                                            }
                                        });
                                    });
                                } else {
                                    each(newIncident.assignedResources, function (ar) {
                                        if (ar.resourceId.toLowerCase() === notify.resource.toLowerCase() &&
                                            ar.departmentId.toLowerCase() === notify.resourceDepartment.toLowerCase()) {
                                            ar.causedBlink = true;
                                        }
                                    });
                                }
                            }
                        }
                    } else {
                        resourceDepartmentMatch = true;
                        resource.causedBlink = true;
                    }

                }
            });
        }

        if (notify.county) {
            hasCounty = true;
            criteriaChecked = true;

            const notifyCounty = notify.county.trim().toLowerCase();
            const newIncidentCounty = newIncident.county.trim().toLowerCase();
            if (oldIncident) {
                if (oldIncident.county.trim().toLowerCase() !== newIncidentCounty) {
                    countyMatch = notifyCounty === newIncidentCounty;
                }
            } else {
                countyMatch = notifyCounty === newIncidentCounty;
            }
        }

        if (notify.resourceStatus) {
            hasResourceStatus = true;
            criteriaChecked = true;
            
            each(newIncident.assignedResources, function (resource) {
                if (resource.resourceStatus.trim() === notify.resourceStatus) {

                    // if oldIncident had resourceId and departmentId, no notify
                    if (oldIncident) {
                        const oldIncidentHasResource = some(oldIncident.assignedResources, function (oldResource) {
                            const rMatch = oldResource.resourceId.toLowerCase() === resource.resourceId.toLowerCase();
                            const dMatch = oldResource.departmentId.toLowerCase() === resource.departmentId.toLowerCase();
                            const rsMatch = oldResource.resourceStatus.toLowerCase() === resource.resourceStatus.toLowerCase();

                            return rMatch && dMatch && rsMatch;
                        });

                        if (oldIncidentHasResource === false) {
                            resourceStatusMatch = true;

                            resourceStatusCallback = function () {
                                // mark RESOURCE red...
                                // get resources from newIncident NOT in oldIncident
                                if (oldIncident) {
                                    const newResourcesMaps = map(newIncident.assignedResources, function (i) {
                                        return {
                                            resourceId: i.resourceId,
                                            resourceStatus: i.resourceStatus
                                        };
                                    });
                                    const oldResourcesMaps = map(oldIncident.assignedResources, function (i) {
                                        return {
                                            resourceId: i.resourceId,
                                            resourceStatus: i.resourceStatus
                                        };
                                    });

                                    const resourceDifferences = differenceWith(newResourcesMaps, oldResourcesMaps, function (a, b) {
                                        return a.resourceId === b.resourceId && a.resourceStatus === b.resourceStatus;
                                    });

                                    each(newIncident.assignedResources, function (ar) {
                                        each(resourceDifferences, function (rd) {
                                            if (ar.resourceId.toLowerCase() === rd.resourceId.toLowerCase() &&
                                                ar.resourceStatus.toLowerCase() === notify.resourceStatus.toLowerCase()) {
                                                ar.causedBlink = true;
                                            }
                                        });

                                        //each(newIncident.assignedResources, function(nr) {

                                        //});
                                    });

                                } else {
                                    each(newIncident.assignedResources, function (ar) {
                                        if (ar.resourceId.toLowerCase() === notify.resource.toLowerCase() &&
                                            ar.resourceStatus.toLowerCase() === notify.resourceStatus.toLowerCase()) {
                                            ar.causedBlink = true;
                                        }
                                    });
                                }
                            }
                        }
                    } else {
                        resourceStatusMatch = true;
                        resource.causedBlink = true;
                    }
                }
            });
        }

        const a = !hasAgency || (hasAgency && agencyMatch);
        const r = !hasResource || (hasResource && resourceMatch);
        const z = !hasZone || (hasZone && zoneMatch);
        const c = !hasCity || (hasCity && cityMatch);
        const co = !hasCounty || (hasCounty && countyMatch);
        const rd = !hasResourceDepartment || (hasResourceDepartment && resourceDepartmentMatch);
        const rs = !hasResourceStatus || (hasResourceStatus && resourceStatusMatch);
        
        const triggerBlink = (criteriaChecked && a && r && z && c && rd && co && rs);

        if (triggerBlink) {
            // mark resources if needed!
            resourceCallback();
            resourceDepartmentCallback();
            resourceStatusCallback();
        }

        shouldBlink = shouldBlink || triggerBlink;

    });

    return shouldBlink;
}